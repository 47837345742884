import React, { useEffect, useState } from "react";
import "./pollWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { DELETE_VOTE, SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET, VOTE_POLL } from "../../../redux/action/dashboardAction";
import { getUserPollVoteApi } from "../../../redux/api/pollApi";
import checkedWhite from "../../../assets/CheckedWhite.png";

function PollWidget({ live, data }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const pollMax = Math.max(...data.answers.map((poll) => poll.votes));
  const [myVotes, setMyVotes] = useState([]);

  if (data === undefined) {
    return null;
  }

  useEffect(() => {
    if (live) {
      const payload = {
        dashboard_id: data.dashboard_id,
        user_id: currentUser.user_id,
      };
      getUserPollVoteApi(payload).then((response) => {
        setMyVotes(response);
      });
    }
  }, []);

  return (
    <div
      className={`widgetView ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "poll",
              title: data.title,
              answers: data.answers,
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "poll" });
        }
      }}
    >
      <div className="widgetTitle">{data.title !== "" ? data.title : "Poll"}</div>
      <div className="widgetContent">
        <div className="pollView">
          {data.answers
            .sort((a, b) => b.votes - a.votes)
            .map((poll, index) => {
              const votedFor = myVotes.some((item) => item.poll_id === poll.poll_id);
              return (
                <div
                  className={`pollOption ${live ? "clickable" : ""}`}
                  key={index}
                  onClick={() => {
                    if (live) {
                      if (votedFor) {
                        // Remove Vote
                        const voteObj = myVotes.find((item) => item.poll_id === poll.poll_id);
                        dispatch({
                          type: DELETE_VOTE,
                          payload: {
                            ...voteObj,
                            event_id: data.event_id,
                          },
                        });
                        setMyVotes(myVotes.filter((item) => item.poll_id !== poll.poll_id));
                      } else {
                        // Create New Vote
                        const voteObj = {
                          vote_id: uuidv4(),
                          poll_id: poll.poll_id,
                          user_id: currentUser.user_id,
                          dashboard_id: data.dashboard_id,
                        };
                        dispatch({
                          type: VOTE_POLL,
                          payload: {
                            ...voteObj,
                            event_id: data.event_id,
                          },
                        });
                        setMyVotes([...myVotes, voteObj]);
                      }
                    }
                  }}
                >
                  {/* Show vote results after at least 1 vote! */}
                  {myVotes.length > 0 ? (
                    <div
                      className="pollResult"
                      style={{
                        width: `${pollMax === 0 ? 0 : Math.min(100, (poll.votes / pollMax) * 100)}%`,
                        backgroundColor: votedFor ? "#4B79F7" : "#999999",
                      }}
                    />
                  ) : null}
                  <div className="answeredPoll">
                    <div className="rowFlexSimple gap6">
                      <img className="icon20" src={checkedWhite} alt="" />
                      <div className="pollQuestion">{poll.question}</div>
                    </div>
                    <div className="pollQuestion">{poll.votes} votes</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default PollWidget;
